var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-w-full tw-justify-center tw-items-center tw-h-full",staticStyle:{"background":"white"}},[_c('v-col',{staticClass:"tw-hidden lg:tw-flex left-side",style:({backgroundImage:("url(" + _vm.bg + ")")}),attrs:{"sm":"12","lg":"5"}},[_c('img',{staticClass:"logo",attrs:{"src":_vm.logo,"alt":"logo"},on:{"click":_vm.goHome}}),_c('h6',{staticClass:"hey-mate tw-mt-10"},[_vm._v("Hey mate!")]),_c('h6',{staticClass:"lets-set tw-py-5"},[_vm._v("Let’s Get You Set")]),_c('h6',{staticClass:"lets-set-desc"},[_vm._v("We’re excited to have you! We just need some more information.")])]),_c('v-col',{staticClass:"right-hand-side",attrs:{"sm":"12","lg":"7"}},[_c('div',{staticClass:"right-hand-side-content"},[_c('img',{staticClass:"logo-mobile tw-flex lg:tw-hidden",attrs:{"src":_vm.logo,"alt":"logo"},on:{"click":_vm.goHome}}),_c('h5',{staticClass:"right-hand-side-header"},[_vm._v("Add a Volunteer")]),_c('h6',{staticClass:"right-hand-side-desc tw-py-4"},[_vm._v("Tell us a few things about you")]),_c('h6',{staticClass:"right-hand-side-desc tw-py-4 tw-flex md:tw-hidden tw-self-center"},[_vm._v("Parent | Guardian")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addVolunteer)}}},[_c('v-row',[_c('v-col',{staticClass:"tw-flex tw-justify-end  tw-flex-col",attrs:{"sm":"12"}},[_c('h6',{staticClass:"right-hand-side-desc"},[_vm._v("Profile Photo")]),_c('button',{staticClass:"button-logo-input",class:_vm.profile ? 'border-0' : '',attrs:{"type":"button"},on:{"click":function($event){return _vm.onProfileImageFile()}}},[(_vm.profileImageLoading)?_c('v-progress-circular',{staticStyle:{"position":"absolute"},attrs:{"size":100,"width":3,"color":"blue","indeterminate":""}}):_vm._e(),(_vm.profileImage)?_c('img',{staticClass:"button-logo-img",attrs:{"src":_vm.profile,"alt":""}}):_c('img',{staticClass:"button-logo-input-img",attrs:{"src":require("@/assets/camera.png"),"alt":""}})],1),_c('input',{ref:"profileImage",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onProfileImagePicked}})])],1),_c('v-row',{staticClass:"mobile"},[_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-text-field',{attrs:{"placeholder":"First name","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.volunteer.firstName),callback:function ($$v) {_vm.$set(_vm.volunteer, "firstName", $$v)},expression:"volunteer.firstName"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"Last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-text-field',{attrs:{"placeholder":"Last name","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.volunteer.lastName),callback:function ($$v) {_vm.$set(_vm.volunteer, "lastName", $$v)},expression:"volunteer.lastName"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mobile"},[_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"placeholder":"Date of Birth","solo":"","hide-details":"","readonly":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"color":"#F66B05"}},[_vm._v(" mdi-calendar-month-outline ")])]},proxy:true}],null,true),model:{value:(_vm.volunteer.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.volunteer, "dateOfBirth", $$v)},expression:"volunteer.dateOfBirth"}},on))]}}],null,true),model:{value:(_vm.dateOfBirthMenu),callback:function ($$v) {_vm.dateOfBirthMenu=$$v},expression:"dateOfBirthMenu"}},[_c('v-date-picker',{attrs:{"locale":"en-in","no-title":"","max":_vm.maxDate,"color":"#F66B05"},on:{"input":function($event){_vm.dateOfBirthMenu = false}},model:{value:(_vm.volunteer.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.volunteer, "dateOfBirth", $$v)},expression:"volunteer.dateOfBirth"}})],1),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"ID Card","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-text-field',{attrs:{"color":"#F66B05","placeholder":"ID Card","solo":"","hide-details":"","type":"text","required":"","readonly":"","append-icon":"mdi-plus"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{staticClass:"upload",attrs:{"text":""},on:{"click":function($event){return _vm.onIdCardFile()}}},[_vm._v("Upload ")])]},proxy:true}],null,true),model:{value:(_vm.cardImage),callback:function ($$v) {_vm.cardImage=$$v},expression:"cardImage"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('input',{ref:"idImage",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onIdCardImagePicked}})],1)],1),_c('v-row',{staticClass:"mobile"},[_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-text-field',{attrs:{"color":"#F66B05","placeholder":"example@google.com","solo":"","hide-details":"","type":"email","required":""},model:{value:(_vm.volunteer.email),callback:function ($$v) {_vm.$set(_vm.volunteer, "email", $$v)},expression:"volunteer.email"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-text-field',{attrs:{"color":"#F66B05","placeholder":"Mobile Number","solo":"","hide-details":"","type":"tel","required":""},model:{value:(_vm.volunteer.phoneNumber),callback:function ($$v) {_vm.$set(_vm.volunteer, "phoneNumber", $$v)},expression:"volunteer.phoneNumber"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mobile"},[_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-combobox',{attrs:{"color":"#F66B05","placeholder":"Country","solo":"","hide-details":"","type":"text","items":_vm.countries,"append-icon":"mdi-chevron-down","item-text":"name","item-value":"name","required":""},on:{"change":_vm.getCountiesOrState},model:{value:(_vm.volunteer.address.country),callback:function ($$v) {_vm.$set(_vm.volunteer.address, "country", $$v)},expression:"volunteer.address.country"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"County Or State","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-combobox',{attrs:{"color":"#F66B05","placeholder":"County Or State","solo":"","hide-details":"","type":"text","append-icon":"mdi-chevron-down","item-text":"name","item-value":"name","items":_vm.countiesOrStates,"required":""},on:{"change":_vm.getCity},model:{value:(_vm.volunteer.address.county),callback:function ($$v) {_vm.$set(_vm.volunteer.address, "county", $$v)},expression:"volunteer.address.county"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mobile"},[_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"House Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-text-field',{attrs:{"placeholder":"House Number","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.volunteer.address.houseNumber),callback:function ($$v) {_vm.$set(_vm.volunteer.address, "houseNumber", $$v)},expression:"volunteer.address.houseNumber"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"Town/City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-combobox',{attrs:{"color":"#F66B05","placeholder":"Town/City","append-icon":"mdi-chevron-down","solo":"","hide-details":"","type":"text","required":"","items":_vm.cities},model:{value:(_vm.volunteer.address.city),callback:function ($$v) {_vm.$set(_vm.volunteer.address, "city", $$v)},expression:"volunteer.address.city"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mobile"},[_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"Postal Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-text-field',{attrs:{"placeholder":"Postal Code","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.volunteer.address.postalCode),callback:function ($$v) {_vm.$set(_vm.volunteer.address, "postalCode", $$v)},expression:"volunteer.address.postalCode"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"Occupation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-text-field',{attrs:{"color":"#F66B05","placeholder":"Occupation","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.volunteer.occupation),callback:function ($$v) {_vm.$set(_vm.volunteer, "occupation", $$v)},expression:"volunteer.occupation"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mobile"},[_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"Work Place","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-text-field',{attrs:{"color":"#F66B05","placeholder":"Work Place","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.volunteer.workPlace),callback:function ($$v) {_vm.$set(_vm.volunteer, "workPlace", $$v)},expression:"volunteer.workPlace"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('validationProvider',{attrs:{"name":"Work Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 tw-mb-4",class:classes},[_c('v-text-field',{attrs:{"color":"#F66B05","placeholder":"Work Phone","solo":"","hide-details":"","type":"tel","required":""},model:{value:(_vm.volunteer.workPhoneNumber),callback:function ($$v) {_vm.$set(_vm.volunteer, "workPhoneNumber", $$v)},expression:"volunteer.workPhoneNumber"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mobile"},[_c('v-checkbox',{attrs:{"color":"#F66B05"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('h6',{staticClass:"right-hand-side-desc "},[_vm._v("By clicking submit, you agree to our "),_c('span',{staticStyle:{"color":"#F66B05"}},[_vm._v("Terms")]),_vm._v(" & "),_c('span',{staticStyle:{"color":"#F66B05"}},[_vm._v("Conditions ")])])]},proxy:true}],null,true),model:{value:(_vm.agree),callback:function ($$v) {_vm.agree=$$v},expression:"agree"}})],1),_c('v-row',{staticClass:"my-8 tw-flex tw-justify-between"},[_c('base-button',{attrs:{"width":"100%","button-text":"Submit","loading":_vm.loading,"type":"submit","disabled":!_vm.agree}})],1)],1)]}}])})],1)]),_c('modal',{attrs:{"dialog":_vm.showModal,"icon":_vm.check,"section":"main","title":"Great, Application Successful","description":"We just sent you a confirmation email. Please check your inbox to confirm your  email.","additional-text":""},on:{"close":_vm.routeToDashboard}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }